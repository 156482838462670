var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"dashboard-ecommerce"}},[_c('b-card',[_c('b-card-text',[_c('b-row',[_c('b-col',[_c('DxGrid',{attrs:{"title":"Downloadable Catalogues","canSelect":"none","data-source":_vm.dataSource},on:{"on-delete":function (v) { return _vm.onDelete(v); },"on-open-modal":function (v) { return _vm.onOpenModal(v); },"on-update-cancel":function (v) { return _vm.loadDownloadableCatalogues(); }},scopedSlots:_vm._u([{key:"columns",fn:function(){return [_c('DxColumn',{attrs:{"data-field":"title","caption":"Title"}}),_c('DxColumn',{attrs:{"data-field":"description","caption":"Description"}})]},proxy:true}])})],1)],1),(_vm.showAddForm)?_c('span',[_c('validation-observer',{ref:"refDownloadableCatalogues",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[(_vm.imagePreview)?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Preview"}},[_c('b-img',{attrs:{"center":"","thumbnail":"","fluid":"","src":_vm.imagePreview}})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","md":_vm.imagePreview ? '6' : '12'}},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Image"}},[_c('b-form-file',{attrs:{"accept":"image/*","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.imageUpload),callback:function ($$v) {_vm.imageUpload=$$v},expression:"imageUpload"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.docLabel}},[_c('b-form-file',{attrs:{"accept":".pdf,.docx","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.fileUpload),callback:function ($$v) {_vm.fileUpload=$$v},expression:"fileUpload"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"Title","vid":"v-title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title","label-for":"v-title"}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"name":"title"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Publish Date","label-for":"v-publish_date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"DD/MM/YYYY","config":{
                        allowInput: true,
                        dateFormat: 'd/m/Y',
                      }},model:{value:(_vm.formData.preview_publish_date),callback:function ($$v) {_vm.$set(_vm.formData, "preview_publish_date", $$v)},expression:"formData.preview_publish_date"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Description","label-for":"v-description"}},[_c('b-form-textarea',{model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Keywords","label-for":"v-keywords"}},[_c('b-form-tags',{attrs:{"id":"v-keywords","remove-on-delete":"","placeholder":"Add Keywords..."},model:{value:(_vm.formData.tags),callback:function ($$v) {_vm.$set(_vm.formData, "tags", $$v)},expression:"formData.tags"}})],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"bg-darken-4 mr-1",attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.hideForm()}}},[_vm._v(" Cancel ")]),_c('b-button',{staticClass:"bg-darken-4",attrs:{"disabled":invalid,"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v(" Save ")])],1)],1)]}}],null,false,629610965)})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }