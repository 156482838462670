<template>
  <section id="dashboard-ecommerce">
    <b-card>
      <b-card-text>
        <b-row>
          <b-col>
            <DxGrid
              title="Downloadable Catalogues"
              canSelect="none"
              :data-source="dataSource"
              @on-delete="(v) => onDelete(v)"
              @on-open-modal="(v) => onOpenModal(v)"
              @on-update-cancel="(v) => loadDownloadableCatalogues()"
            >
              <template #columns>
                <DxColumn data-field="title" caption="Title" />
                <DxColumn data-field="description" caption="Description" />
              </template>
            </DxGrid>
          </b-col>
        </b-row>

        <span v-if="showAddForm">
          <validation-observer
            ref="refDownloadableCatalogues"
            #default="{ invalid }"
          >
            <b-row>
              <b-col cols="12" md="6" v-if="imagePreview">
                <b-form-group label="Preview">
                  <b-img center thumbnail fluid :src="imagePreview" />
                </b-form-group>
              </b-col>

              <b-col cols="12" :md="imagePreview ? '6' : '12'">
                <b-row>
                  <b-col>
                    <b-form-group label="Image">
                      <b-form-file
                        accept="image/*"
                        v-model="imageUpload"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group :label="docLabel">
                      <b-form-file
                        accept=".pdf,.docx"
                        v-model="fileUpload"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <validation-provider
                      #default="{ errors }"
                      name="Title"
                      vid="v-title"
                      rules="required"
                    >
                      <b-form-group label="Title" label-for="v-title">
                        <b-form-input
                          :class="errors.length > 0 ? 'is-invalid' : null"
                          v-model="formData.title"
                          name="title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Publish Date"
                      label-for="v-publish_date"
                    >
                      <flat-pickr
                        v-model="formData.preview_publish_date"
                        placeholder="DD/MM/YYYY"
                        class="form-control"
                        :config="{
                          allowInput: true,
                          dateFormat: 'd/m/Y',
                        }"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group label="Description" label-for="v-description">
                      <b-form-textarea v-model="formData.description" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group label="Keywords" label-for="v-keywords">
                      <b-form-tags
                        id="v-keywords"
                        remove-on-delete
                        placeholder="Add Keywords..."
                        v-model="formData.tags"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="text-right">
                <b-button
                  size="sm"
                  @click="hideForm()"
                  variant="danger"
                  class="bg-darken-4 mr-1"
                >
                  Cancel
                </b-button>
                <b-button
                  :disabled="invalid"
                  size="sm"
                  @click="onSubmit()"
                  variant="primary"
                  class="bg-darken-4"
                >
                  Save
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </span>
      </b-card-text>
    </b-card>
  </section>
</template>

<script>
import downloadableCataloguesApi from '@api/downloadable_catalogues';
import { getImage } from '@/global-functions';
// @todo change from utils classes to global-function
import { UtilsDate, unixToDisplayDateTime } from '@/utils/classes/dates';
import { required } from '@validations';
import { queryParameters } from '@/schema';

export default {
  name: 'DownloadableCataloguesPage',
  components: {},
  data: () => ({
    required,
    showAddForm: false,
    dataSource: [],

    imageUpload: null,
    imagePreview: null,
    imageName: null,
    imageExt: null,

    fileUpload: null,
    fileName: null,
    fileExt: null,

    formData: {
      id: 0,
      title: '',
      description: '',
      publish_date: '',
      thumbnail: '',
      document: '',
      keywords: '',
      catalog_type: '',
      status: 'O',

      tags: [],
      thumbnailbase64: '',
      documentbase64: '',
      preview_publish_date: '',
    },
  }),
  computed: {
    docLabel: {
      get() {
        if (this.formData.id) {
          return `File: ${this.formData.document}`;
        }

        return 'File';
      },
      set(v) {
        //
      },
    },
  },
  watch: {
    imageUpload(event) {
      this.onUpload(event, 'image');
    },
    fileUpload(event) {
      this.onUpload(event, 'file');
    },
  },
  mounted() {
    this.loadDownloadableCatalogues();
  },
  methods: {
    loadDownloadableCatalogues() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      downloadableCataloguesApi
        .list(params)
        .then(({ data }) => {
          if (data != null) {
            this.dataSource = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      const hideFormLoadDownloadableCatalogues = () => {
        this.loadDownloadableCatalogues();
        this.hideForm();
      };

      this.$refs.refDownloadableCatalogues.validate().then((success) => {
        if (success) {
          const date = new UtilsDate(this.formData.preview_publish_date);
          this.formData.publishdate = date.dateToUnix();

          const tags = this.formData.tags;
          this.formData.keywords = tags ? tags.join(',') : '';

          if (this.formData.id) {
            downloadableCataloguesApi
              .update(this.formData)
              .then(({ data }) => {
                hideFormLoadDownloadableCatalogues();
              })
              .catch(() => {
                //
              })
              .finally(() => {
                //
              });
            return;
          }

          downloadableCataloguesApi
            .add(this.formData)
            .then(({ data }) => {
              hideFormLoadDownloadableCatalogues();
            })
            .catch(() => {
              //
            })
            .finally(() => {
              //
            });
        }
      });
    },
    onOpenModal(event) {
      this.hideForm();
      this.showAddForm = event.value;

      if (event.name === 'edit') {
        this.onEdit(event.data);
      }
    },
    onEdit(data) {
      this.$nextTick(() => {
        const getImageData = {
          model: 'catalog',
          id: data.id,
          thumbnail: data.thumbnail,
        };

        data.url = getImage(getImageData);
        data.tags = data.keywords.split(',');

        data.preview_publish_date = unixToDisplayDateTime(data.publishdate);

        const b64 = data.thumbnailbase64;
        this.imagePreview = b64 !== '' ? b64 : data.url;

        this.formData = Object.assign({}, data);
      });
    },
    onDelete(event) {
      downloadableCataloguesApi
        .delete(event)
        .then(() => {
          this.loadDownloadableCatalogues();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onUpload(event, type) {
      if (event) {
        const reader = new window.FileReader();

        reader.readAsDataURL(event);
        reader.onload = () => {
          if (type === 'image') {
            this.imageExt = event.name.split('.')[1];
            this.imageName = event.name;
            this.imagePreview = reader.result;

            this.formData.thumbnail = event.file;
            this.formData.thumbnailbase64 = reader.result;

            return;
          }

          this.fileExt = event.name.split('.')[1];
          this.fileName = event.name;
          this.formData.document = event.name;
          this.formData.documentbase64 = reader.result;
        };
      }
    },
    hideForm() {
      this.showAddForm = false;
      const defaultForm = {
        id: 0,
        name: '',
        image_url: '',
        website: '',
        thumbnail: '',
        thumbnailbase64: '',
        status: 'O',
      };

      this.$nextTick(() => {
        this.imagePreview = null;
        this.formData = Object.assign({}, defaultForm);
      });
    },
  },
};
</script>

<style></style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
