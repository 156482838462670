import api from '@apiInstance'

export default {
  add(data) {
    return api.post('catalogs', data)
  },
  get(id) {
    return api.fetch('catalogs/' + id)
  },
  list(params) {
    return api.fetch('/catalogs', params)
  },
  update(data) {
    return api.patch('catalogs', data)
  },
  delete(id) {
    return api.remove('catalogs', id)
  },
}
